import type { ParamMatcher } from "@sveltejs/kit";

const DASH_URLS = [
  "apps",
  "logs",
  "secrets",
  "storage",
  "sandboxes",
  "containers",
];

// This matcher is used to redirect URLs like `/apps` to `/apps/:workspace/:environment`.
export const match: ParamMatcher = (param) => {
  return DASH_URLS.includes(param);
};
