import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://d75f7cb747cd4fe8ac03973ae3d39fec@o1108641.ingest.sentry.io/6688362",
  environment: import.meta.env.MODE,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
